import { createContext, FC, useState } from 'react';
import { ResponseCompanyParams } from '../../models/company';
import { DistrictSortingFields, Order } from '../../models/types';
import { ResponseDistrictParams } from '../../models/district';

interface IDistrictsContext {
  districtsUpdate: boolean;
  toggleDistrictsUpdate: () => void;
  districts: ResponseDistrictParams[];
  setDistricts: (val: ResponseDistrictParams[]) => void;
  companies: ResponseCompanyParams[];
  setCompanies: (val: ResponseCompanyParams[]) => void;
  filterCompany: ResponseCompanyParams;
  setFilterCompany: (val: ResponseCompanyParams) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: keyof DistrictSortingFields;
  setOrderBy: (val: keyof DistrictSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const DistrictsContext = createContext<IDistrictsContext>(
  {} as IDistrictsContext
);

export const DistrictsProvider: FC = ({ children }) => {
  // Update
  const [districtsUpdate, setDistrictsUpdate] = useState(false);

  // Data
  const [districts, setDistricts] = useState<ResponseDistrictParams[]>([]);
  const [companies, setCompanies] = useState<ResponseCompanyParams[]>([]);

  // Filtering
  const [filterCompany, setFilterCompany] = useState<ResponseCompanyParams>(
    {} as ResponseCompanyParams
  );

  // Pagination props
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [count, setCount] = useState<number>(0);

  // Sorting
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof DistrictSortingFields>('district');

  // Loading
  const [loading, setLoading] = useState(false);

  const toggleUpdate = (): void => setDistrictsUpdate(!districtsUpdate);

  return (
    <DistrictsContext.Provider
      value={{
        districtsUpdate,
        toggleDistrictsUpdate: toggleUpdate,
        districts,
        setDistricts,
        companies,
        setCompanies,
        filterCompany,
        setFilterCompany,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </DistrictsContext.Provider>
  );
};
