import { createContext, FC, useState } from 'react';
import { ResponseCompanyParams } from '../../models/company';
import { CompanySortingFields, Order } from '../../models/types';

interface ICompaniesContext {
  companiesUpdate: boolean;
  toggleCompaniesUpdate: () => void;
  companies: ResponseCompanyParams[];
  setCompanies: (val: ResponseCompanyParams[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: keyof CompanySortingFields;
  setOrderBy: (val: keyof CompanySortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const CompaniesContext = createContext<ICompaniesContext>(
  {} as ICompaniesContext
);

export const CompaniesProvider: FC = ({ children }) => {
  // Update
  const [companiesUpdate, setCompaniesUpdate] = useState(false);

  // Data
  const [companies, setCompanies] = useState<ResponseCompanyParams[]>([]);

  // Pagination props
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [count, setCount] = useState<number>(0);

  // Sorting
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof CompanySortingFields>('name');

  // Loading
  const [loading, setLoading] = useState(false);

  const toggleUpdate = (): void => setCompaniesUpdate(!companiesUpdate);

  return (
    <CompaniesContext.Provider
      value={{
        companiesUpdate,
        toggleCompaniesUpdate: toggleUpdate,
        companies,
        setCompanies,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
};
