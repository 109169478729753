import { createContext, FC, useState } from 'react';
import { Order, UsersSortingFields } from '../models/types';
import { UsersResponseParams } from '../models/users';

interface IUsersContext {
  // getImageLink: (key: string) => Promise<string>;
  usersUpdate: boolean;
  toggleUsersUpdate: () => void;
  users: UsersResponseParams[];
  setUsers: (val: UsersResponseParams[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: keyof UsersSortingFields;
  setOrderBy: (val: keyof UsersSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const UsersContext = createContext<IUsersContext>({} as IUsersContext);

export const UsersProvider: FC = ({ children }) => {
  // Update
  const [usersUpdate, setUsersUpdate] = useState(false);

  // Data
  const [users, setUsers] = useState<UsersResponseParams[]>([]);

  // Pagination props
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [count, setCount] = useState<number>(0);

  // Sorting
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof UsersSortingFields>('createdAt');

  // Loading
  const [loading, setLoading] = useState(false);

  const toggleUsersUpdate = (): void => setUsersUpdate(!usersUpdate);

  return (
    <UsersContext.Provider
      value={{
        usersUpdate,
        toggleUsersUpdate,
        users,
        setUsers,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
