import { createContext, FC, useState } from 'react';
import { PlantingTreesSortingFields, Order } from 'src/models/types';
import { PlantingTrees } from 'src/models/planting-trees';

interface IPlantingTreesContext {
  plantingTreesUpdate: boolean;
  togglePlantingTreesUpdate: () => void;
  plantingTrees: PlantingTrees[];
  setPlantingTrees: (val: PlantingTrees[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: keyof PlantingTreesSortingFields;
  setOrderBy: (val: keyof PlantingTreesSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const PlantingTreesContext = createContext<IPlantingTreesContext>({} as IPlantingTreesContext);

export const PlantingTreesProvider: FC = ({ children }) => {
  // Update
  const [plantingTreesUpdate, setPlantingTreesUpdate] = useState(false);

  // Data
  const [plantingTrees, setPlantingTrees] = useState<PlantingTrees[]>([]);

  // Pagination props
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [count, setCount] = useState<number>(0);

  // Sorting
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof PlantingTreesSortingFields>('createdAt');

  // Loading
  const [loading, setLoading] = useState(false);

  const togglePlantingTreesUpdate = (): void => setPlantingTreesUpdate(!plantingTreesUpdate);

  return (
    <PlantingTreesContext.Provider
      value={{
        plantingTreesUpdate,
        togglePlantingTreesUpdate,
        plantingTrees,
        setPlantingTrees,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </PlantingTreesContext.Provider>
  );
};
