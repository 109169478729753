import router from 'src/routers/router';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ThemeProvider from './theme/ThemeProvider';
import awsconfig from './aws-exports';
import '@aws-amplify/ui-react/styles.css';
import { useRoutes } from 'react-router-dom';
import { Amplify, Auth } from 'aws-amplify';
import { AmplifyUser } from '@aws-amplify/ui';
import { CssBaseline } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import SuspenseLoader from './components/LoadingIndicators/SuspenseLoader';

Amplify.configure(awsconfig);

type Props = {
  user: AmplifyUser;
};

function App() {
  const content = useRoutes(router);
  const [notAdmin, setNotAdmin] = useState(false);

  const components = {
    SignIn: {
      Footer() {
        return <></>;
      }
    }
  };

  useEffect(() => {
    if (notAdmin) {
      Auth.signOut();
      setNotAdmin(false);
    }
  }, [notAdmin]);
  return (
    <Authenticator
      hideSignUp={true}
      socialProviders={[]}
      components={components}
    >
      {({ user }: Props) => {
        const accessToken = user.getSignInUserSession()?.getAccessToken();
        if (accessToken?.payload) {
          if (
            !('cognito:groups' in accessToken?.payload) ||
            !accessToken?.payload['cognito:groups'].includes('reAppAdmin') ||
            accessToken?.payload['cognito:groups'].includes('reAppCommon')
          ) {
            setTimeout(() => {
              setNotAdmin(true);
            }, 0);
            return <SuspenseLoader />;
          }
        }
        return (
          <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              {content}
            </LocalizationProvider>
          </ThemeProvider>
        );
      }}
    </Authenticator>
  );
}

export default App;
