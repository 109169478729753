import { Loader } from './loader.router';
import { lazy } from 'react';
import SidebarLayout from '../layouts/SidebarLayout';
import { SettingsProvider } from '../contexts/dashboards/SettingsContext';

// Status

const Settings = Loader(lazy(() => import('src/content/settings')));

export const settings = {
  path: '',
  element: <SidebarLayout />,
  children: [
    {
      path: '/settings',
      element: (
        <SettingsProvider>
          <Settings />
        </SettingsProvider>
      )
    }
  ]
};
