import { RouteObject } from 'react-router';
import { landings } from './landings.router';
import { dashboards } from './dashboards.router';
import { eWaste } from './e-waste.router';
import { settings } from './settings.router';
import { support } from './support.router';
import { users } from './users.router';

const routes: RouteObject[] = [
  landings,
  dashboards,
  eWaste,
  settings,
  support,
  users
];

export default routes;
