import { createContext, FC, useState } from 'react';
import { ContainersSchedulesSortingFields, Order } from '../../models/types';
import { ContainerType, ListContainersSchedulesParams, ResponseListContainersSchedulesParams, ResponseNestedContainersScheduleParams } from '../../models/containers-schedules';
import { ResponseDistrictParams } from '../../models/district';
import { ResponseCompanyParams } from '../../models/company';
import { ReContainerType } from 'src/models/container';


interface IContainersSchedulesContext {
    containersSchedulesUpdate: boolean;
    containersSchedulesCompaniesUpdate: boolean;
    toggleContainersSchedulesCompaniesUpdate: () => void;
    toggleContainersSchedulesUpdate: () => void;
    page: number;
    setPage: (val: number) => void;
    limit: number;
    setLimit: (val: number) => void;
    count: number;
    setCount: (val: number) => void;
    order: Order;
    setOrder: (val: Order) => void;
    orderBy: keyof ContainersSchedulesSortingFields;
    setOrderBy: (val: keyof ContainersSchedulesSortingFields) => void;
    setContainersSchedulesLoading: (val: boolean) => void;
    containersSchedules: ResponseNestedContainersScheduleParams[];
    setContainersSchedules: (val: ResponseNestedContainersScheduleParams[]) => void;
    districts: ResponseDistrictParams[];
    setDistricts: (val: ResponseDistrictParams[]) => void;
    companies: ResponseCompanyParams[];
    setCompanies: (val: ResponseCompanyParams[]) => void;
    filterCompany: ResponseCompanyParams;
    setFilterCompany: (val: ResponseCompanyParams) => void;
    filterDistrict: ResponseDistrictParams;
    setFilterDistrict: (val: ResponseDistrictParams) => void;
    loading: boolean;
    setDistrictsLoading: (val: boolean) => void;
    setCompaniesLoading: (val: boolean) => void;
    filterTypes: ContainerType[];
    setFilterTypes: (val: ContainerType[]) => void;
}

export const ContainersSchedulesContext = createContext<IContainersSchedulesContext>(
    {} as IContainersSchedulesContext
);

export const ContainersSchedulesProvider: FC = ({ children }) => {
    // Update
    const [containersSchedulesUpdate, setContainersSchedulesUpdate] = useState(false);
    const [containersSchedulesCompaniesUpdate, setContainersSchedulesCompaniesUpdate] = useState(false);

    // Data
    const [districts, setDistricts] = useState<ResponseDistrictParams[]>([]);
    const [companies, setCompanies] = useState<ResponseCompanyParams[]>([]);
    const [containersSchedules, setContainersSchedules] = useState<ResponseNestedContainersScheduleParams[]>([]);

    // Filtering
    const [filterCompany, setFilterCompany] = useState<ResponseCompanyParams>(
        {} as ResponseCompanyParams
    );
    const [filterDistrict, setFilterDistrict] = useState<ResponseDistrictParams>(
        {} as ResponseDistrictParams
    );
    const [filterTypes, setFilterTypes] = useState<ContainerType[]>([]);

    // Pagination props
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [count, setCount] = useState<number>(0);

    // Sorting
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] =
        useState<keyof ContainersSchedulesSortingFields>('type');

    // Loading
    const [containersSchedulesLoading, setContainersSchedulesLoading] = useState(false);
    const [districtsLoading, setDistrictsLoading] = useState(false);
    const [companiesLoading, setCompaniesLoading] = useState(false);

    const loading = containersSchedulesLoading
        || districtsLoading || companiesLoading;

    const toggleContainersSchedulesUpdate = (): void =>
        setContainersSchedulesUpdate(!containersSchedulesUpdate);

    const toggleContainersSchedulesCompaniesUpdate = (): void =>
        setContainersSchedulesCompaniesUpdate(!containersSchedulesCompaniesUpdate);
    return (
        <ContainersSchedulesContext.Provider
            value={{
                containersSchedulesUpdate,
                containersSchedulesCompaniesUpdate,
                toggleContainersSchedulesCompaniesUpdate,
                toggleContainersSchedulesUpdate,
                districts,
                setDistricts,
                companies,
                setCompanies,
                page,
                setPage,
                limit,
                setLimit,
                count,
                setCount,
                order,
                setOrder,
                orderBy,
                setOrderBy,
                setContainersSchedulesLoading,
                containersSchedules,
                setContainersSchedules,
                setCompaniesLoading,
                setDistrictsLoading,
                filterCompany,
                setFilterCompany,
                filterDistrict,
                setFilterDistrict,
                loading,
                filterTypes,
                setFilterTypes
            }}
        >
            {children}
        </ContainersSchedulesContext.Provider>
    );
};
