import { EWasteRequest } from '../../models/e-waste.request';
import { createContext, FC, useState } from 'react';
import { EWasteRequestSortingFields, Order } from '../../models/types';

interface IEWasteRequestsContext {
  requestsUpdate: boolean;
  toggleRequestsUpdate: () => void;
  requests: EWasteRequest[];
  setRequests: (val: EWasteRequest[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: keyof EWasteRequestSortingFields;
  setOrderBy: (val: keyof EWasteRequestSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  filterStatuses: string[];
  setFilterStatuses: (val: string[]) => void;
}

export const EWasteRequestsContext = createContext<IEWasteRequestsContext>(
  {} as IEWasteRequestsContext
);

export const EWasteRequestsProvider: FC = ({ children }) => {
  // Update
  const [requestsUpdate, setRequestsUpdate] = useState(false);

  // Data
  const [requests, setRequests] = useState<EWasteRequest[]>([]);

  // Pagination props
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [count, setCount] = useState<number>(0);

  // Sorting
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof EWasteRequestSortingFields>('eWasteRequestState');

  // Filter
  const [filterStatuses, setFilterStatuses] = useState<string[]>([]);

  // Loading
  const [loading, setLoading] = useState(false);

  const toggleRequestsUpdate = (): void => setRequestsUpdate(!requestsUpdate);

  return (
    <EWasteRequestsContext.Provider
      value={{
        requestsUpdate,
        toggleRequestsUpdate,
        requests,
        setRequests,
        order,
        orderBy,
        setOrder,
        setOrderBy,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        loading,
        setLoading,
        filterStatuses,
        setFilterStatuses
      }}
    >
      {children}
    </EWasteRequestsContext.Provider>
  );
};
