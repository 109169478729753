/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apiVpcCommon",
            "endpoint": "https://bvfnygh4pa.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:77100d64-f042-4e79-9c30-dbdb6b911bc0",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_yWoJZRQa8",
    "aws_user_pools_web_client_id": "2lslgp87977hfmfbtnu65l3oai",
    "oauth": {
        "domain": "reapplication57dc9eae-57dc9eae-prod.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "reapp://",
        "redirectSignOut": "reapp://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "reapplication-restorage143718-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
