import { createContext, FC, useState } from 'react';
import { Order, RequestSortingFields } from '../models/types';
import { SupportRequest } from '../models/support-request';
import { Amplify, Storage } from 'aws-amplify';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

interface ISupportRequestContext {
  getImageLink: (key: string) => Promise<string>;
  requestsUpdate: boolean;
  toggleRequestsUpdate: () => void;
  requests: SupportRequest[];
  setRequests: (val: SupportRequest[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: keyof RequestSortingFields;
  setOrderBy: (val: keyof RequestSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const SupportRequestContext = createContext<ISupportRequestContext>(
  {} as ISupportRequestContext
);

export const SupportRequestProvider: FC = ({ children }) => {
  // Update
  const [requestsUpdate, setRequestsUpdate] = useState(false);

  // Data
  const [requests, setRequests] = useState<SupportRequest[]>([]);

  // Pagination props
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [count, setCount] = useState<number>(0);

  // Sorting
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] =
    useState<keyof RequestSortingFields>('createdAt');

  // Loading
  const [loading, setLoading] = useState(false);

  const toggleUpdate = (): void => setRequestsUpdate(!requestsUpdate);

  // Storage
  const getImageLink = async (key: string) => {
    const format = key.split('.').pop();
    const customPrefix = { public: 'public/upload/' };
    const imageLink = await Storage.get(key, {
      contentType: `image/${format}`,
      customPrefix,
    });
    return imageLink
  };

  return (
    <SupportRequestContext.Provider
      value={{
        getImageLink,
        requestsUpdate,
        toggleRequestsUpdate: toggleUpdate,
        requests,
        setRequests,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </SupportRequestContext.Provider>
  );
};
