import BaseLayout from '../layouts/BaseLayout';
import { Navigate } from 'react-router-dom';
import { Loader } from './loader.router';
import { lazy } from 'react';

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);

export const landings = {
  path: '',
  element: <BaseLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to="/dashboards" replace />
    },
    {
      path: '*',
      element: <Status404 />
    }
  ]
};
