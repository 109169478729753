import { API } from 'aws-amplify';
import PaginationParams from './params';
import { ResponseListUsersParams, UsersResponseParams } from '../models/users';

const apiName = 'apiVpcCommon';
const path = '/users';

export const listUsers = async (
  params: PaginationParams = new PaginationParams()
): Promise<ResponseListUsersParams> => {
  const myInit = {
    headers: {},
    queryStringParameters: params
  };

  return API.get(apiName, path, myInit);
};

export const getUserInfo = async (
  userId: string
): Promise<UsersResponseParams> => {
  const myInit = {
    headers: {}
  };
  return API.get(apiName, `${path}/${userId}`, myInit);
};
