import { Loader } from './loader.router';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from '../layouts/SidebarLayout';
import { SupportRequestProvider } from '../contexts/SupportRequestContext';

// E-Waste
const SupportRequests = Loader(lazy(() => import('src/content/support')));

export const support = {
  path: 'support',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: <Navigate to="/support/requests" replace />
    },
    {
      path: 'requests',
      element: (
        <SupportRequestProvider>
          <SupportRequests />
        </SupportRequestProvider>
      )
    }
  ]
};
