import { Loader } from './loader.router';
import { lazy } from 'react';
import SidebarLayout from '../layouts/SidebarLayout';
import { UsersProvider } from '../contexts/UsersContext';

const Users = Loader(lazy(() => import('src/content/users')));

export const users = {
  path: 'users',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: (
        <UsersProvider>
          <Users />
        </UsersProvider>
      )
    }
  ]
};
