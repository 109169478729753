import { useContext, useEffect, useRef, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  Popover,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { Auth } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import { getUserInfo } from 'src/api-calls/users';
import { UsersResponseParams } from 'src/models/users';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);


const HeaderUserbox = (): JSX.Element => {
  const nav = useNavigate();

  const [userInfo, setUserInfo] = useState<UsersResponseParams>();
  const [profilePicture, setProfilePicture] = useState<string>('');

  useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      if ('custom:db_id' in user.attributes) {
        const userInfo = await getUserInfo(
          user.attributes['custom:db_id']
        );
        setUserInfo(userInfo);
        if (userInfo?.profilePicture) {
          const format = userInfo?.profilePicture.split('.').pop();
          const customPrefix = { public: 'public/avatars/' };
          const imageLink = await Storage.get(userInfo?.profilePicture, {
            contentType: `image/${format}`,
            customPrefix,
          });
          setProfilePicture(imageLink)
        }
      }
    })();
  }, []);

  const user = {
    name: userInfo?.name || 'Admin Username',
    avatar: profilePicture || ''
  };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleSignOut = (): void => {
    Auth.signOut()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        nav('/');
      });
  };

  const avatarGenerator = () => {
    if (!user?.name) {
      return ''
    }
    return user?.name.split(' ')
      .reduce((acc, cur) => acc + cur[0].toLocaleUpperCase(), '');
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar alt={user.name} src={user.avatar}>
          {avatarGenerator()}
        </Avatar>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar alt={user.name} src={user.avatar}>
            {avatarGenerator()}
          </Avatar>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleSignOut}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
