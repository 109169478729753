import { Loader } from './loader.router';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from '../layouts/SidebarLayout';
import { CompaniesProvider } from '../contexts/dashboards/CompaniesContext';
import { ContainersIssuesProvider } from '../contexts/dashboards/ContainersIssuesContext';
import { ContainersSchedulesProvider } from '../contexts/dashboards/ContainersSchedulesContext';
import { DistrictsProvider } from '../contexts/dashboards/DistrictsContext';
import { ContainersProvider } from '../contexts/dashboards/ContainersContext';
import { PlantingTreesProvider } from '../contexts/dashboards/PlantingTreesContext';


// Dashboards
const Companies = Loader(
  lazy(() => import('src/content/dashboards/Companies'))
);
const Districts = Loader(
  lazy(() => import('src/content/dashboards/Districts'))
);
const Containers = Loader(
  lazy(() => import('src/content/dashboards/Containers'))
);

const ContainersIssues = Loader(
  lazy(() => import('src/content/dashboards/ContainersIssues'))
);

const ContainersSchedules = Loader(
  lazy(() => import('src/content/dashboards/ContainersSchedules'))
);

const PlantingTrees = Loader(
  lazy(() => import('src/content/dashboards/PlantingsTrees'))
);

export const dashboards = {
  path: 'dashboards',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: <Navigate to="/dashboards/companies" replace />
    },
    {
      path: 'companies',
      element: (
        <CompaniesProvider>
          <Companies />
        </CompaniesProvider>
      )
    },
    {
      path: 'districts',
      element: (
        <DistrictsProvider>
          <Districts />
        </DistrictsProvider>
      )
    },
    {
      path: 'containers',
      element: (
        <ContainersProvider>
          <Containers />
        </ContainersProvider>
      )
    },
    {
      path: 'issues',
      element: (
        <ContainersIssuesProvider>
          <ContainersIssues />
        </ContainersIssuesProvider>
      )
    },
    {
      path: 'schedules',
      element: (
        <ContainersSchedulesProvider>
          <ContainersSchedules />
        </ContainersSchedulesProvider>
      )
    },
    {
      path: 'planting-trees',
      element: (
        <PlantingTreesProvider>
          <PlantingTrees />
        </PlantingTreesProvider>
      )
    }
  ]
};
