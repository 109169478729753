import { createContext, FC, useState } from 'react';
import { ContainersConditionsSortingFields, Order } from '../../models/types';
import { ReContainerConditionType, ResponseContainersConditionsParams } from '../../models/containers-issues';
import { ResponseDistrictParams } from '../../models/district';
import { ResponseCompanyParams } from '../../models/company';


interface IContainersIssuesContext {
    containersIssuesUpdate: boolean;
    toggleContainersIssuesUpdate: () => void;
    containersIssuesCompaniesUpdate: boolean;
    toggleContainersIssuesCompaniesUpdate: () => void;
    page: number;
    setPage: (val: number) => void;
    limit: number;
    setLimit: (val: number) => void;
    count: number;
    setCount: (val: number) => void;
    order: Order;
    setOrder: (val: Order) => void;
    orderBy: keyof ContainersConditionsSortingFields;
    setOrderBy: (val: keyof ContainersConditionsSortingFields) => void;
    setContainersIssuesLoading: (val: boolean) => void;
    containersIssues: ResponseContainersConditionsParams[];
    setContainersIssues: (val: ResponseContainersConditionsParams[]) => void;
    districts: ResponseDistrictParams[];
    setDistricts: (val: ResponseDistrictParams[]) => void;
    companies: ResponseCompanyParams[];
    setCompanies: (val: ResponseCompanyParams[]) => void;
    filterCompany: ResponseCompanyParams;
    setFilterCompany: (val: ResponseCompanyParams) => void;
    filterDistrict: ResponseDistrictParams;
    setFilterDistrict: (val: ResponseDistrictParams) => void;
    filterIsFixed: boolean;
    setFilterIsFixed: (val: boolean) => void;
    loading: boolean;
    setDistrictsLoading: (val: boolean) => void;
    setCompaniesLoading: (val: boolean) => void;
    filterConditions: ReContainerConditionType[];
    setFilterConditions: (val: ReContainerConditionType[]) => void;
}

export const ContainersIssuesContext = createContext<IContainersIssuesContext>(
    {} as IContainersIssuesContext
);

export const ContainersIssuesProvider: FC = ({ children }) => {
    // Update
    const [containersIssuesUpdate, setContainersIssuesUpdate] = useState(false);
    const [containersIssuesCompaniesUpdate, setContainersIssuesCompaniesUpdate] = useState(false);
    // Data
    const [districts, setDistricts] = useState<ResponseDistrictParams[]>([]);
    const [companies, setCompanies] = useState<ResponseCompanyParams[]>([]);
    const [containersIssues, setContainersIssues] = useState<ResponseContainersConditionsParams[]>([]);

    // Filtering
    const [filterCompany, setFilterCompany] = useState<ResponseCompanyParams>(
        {} as ResponseCompanyParams
    );
    const [filterDistrict, setFilterDistrict] = useState<ResponseDistrictParams>(
        {} as ResponseDistrictParams
    );
    const [filterConditions, setFilterConditions] = useState<ReContainerConditionType[]>([]);
    const [filterIsFixed, setFilterIsFixed] = useState<boolean>(false);

    // Pagination props
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [count, setCount] = useState<number>(0);

    // Sorting
    const [order, setOrder] = useState<Order>('desc');
    const [orderBy, setOrderBy] =
        useState<keyof ContainersConditionsSortingFields>('address');

    // Loading
    const [containersIssuesLoading, setContainersIssuesLoading] = useState(false);
    const [districtsLoading, setDistrictsLoading] = useState(false);
    const [companiesLoading, setCompaniesLoading] = useState(false);

    const loading = containersIssuesLoading
        || districtsLoading || companiesLoading;

    const toggleContainersIssuesUpdate = (): void =>
        setContainersIssuesUpdate(!containersIssuesUpdate);
    const toggleContainersIssuesCompaniesUpdate = (): void =>
        setContainersIssuesCompaniesUpdate(!containersIssuesCompaniesUpdate);

    return (
        <ContainersIssuesContext.Provider
            value={{
                containersIssuesUpdate,
                toggleContainersIssuesCompaniesUpdate,
                containersIssuesCompaniesUpdate,
                toggleContainersIssuesUpdate,
                districts,
                setDistricts,
                companies,
                setCompanies,
                page,
                setPage,
                limit,
                setLimit,
                count,
                setCount,
                order,
                setOrder,
                orderBy,
                setOrderBy,
                setContainersIssuesLoading,
                containersIssues,
                setContainersIssues,
                setCompaniesLoading,
                setDistrictsLoading,
                filterCompany,
                setFilterCompany,
                filterDistrict,
                setFilterDistrict,
                filterConditions,
                filterIsFixed,
                setFilterIsFixed,
                setFilterConditions,
                loading
            }}
        >
            {children}
        </ContainersIssuesContext.Provider>
    );
};
