import { createContext, FC, useState } from 'react';
import SettingsParams from '../../models/settings';

interface ISettingsContext {
  settingsUpdate: boolean;
  toggleSettingsUpdate: () => void;
  settings: SettingsParams | undefined;
  setSettings: (val: SettingsParams) => void;
  setLoading: (val: boolean) => void;
  loading: boolean;
}

export const SettingsContext = createContext<ISettingsContext>(
  {} as ISettingsContext
);
export const SettingsProvider: FC = ({ children }) => {
  // Update
  const [settingsUpdate, setSettingsUpdate] = useState(false);

  // Data
  const [settings, setSettings] = useState<SettingsParams>();

  // Loading
  const [loading, setLoading] = useState(false);

  const toggleSettingsUpdate = (): void =>
    setSettingsUpdate(!settingsUpdate);

  return (
    <SettingsContext.Provider
      value={{
        settingsUpdate,
        toggleSettingsUpdate,
        settings,
        setSettings,
        setLoading,
        loading,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
