import { Loader } from './loader.router';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';
import SidebarLayout from '../layouts/SidebarLayout';
import { EWasteLocationsProvider } from '../contexts/e-waste/EWasteLocationsContext';
import { EWasteRequestsProvider } from '../contexts/e-waste/EWasteRequestsContext';

// E-Waste
const EWasteLocations = Loader(
  lazy(() => import('src/content/e-waste/Locations'))
);

const EWasteRequests = Loader(
  lazy(() => import('src/content/e-waste/Requests'))
);

export const eWaste = {
  path: 'e-waste',
  element: <SidebarLayout />,
  children: [
    {
      path: '',
      element: <Navigate to="/e-waste/locations" replace />
    },
    {
      path: 'locations',
      element: (
        <EWasteLocationsProvider>
          <EWasteLocations />
        </EWasteLocationsProvider>
      )
    },
    {
      path: 'requests',
      element: (
        <EWasteRequestsProvider>
          <EWasteRequests />
        </EWasteRequestsProvider>
      )
    }
  ]
};
